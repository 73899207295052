.gradient-skills {
  background: #000000;
  background: linear-gradient(
    360deg,
    #000000 0%,
    #998599 50%,
    #000000 100%
  );
      height: 25rem;
    position: relative;
    border-radius: 16px;
    margin: 40px 0;
    /* padding: 0 4%; */
    display: flex;
    justify-content: center;
    align-items: center;
 overflow: hidden;
  
}
@media only screen and (max-width: 767px) {
  .gradient-skills {
    height: 46rem;
    padding: 4%;
    display: flex;
  }
}