.up.show {
    right: 0px;
}

.up {
    position: relative;
    bottom: 3%;
    right: -100px;
    background-color: #FABB25;
    color: white;
    font-weight: bold;
    font-size: 25px;
    line-height: 45px;
    border-radius: 50%;
    border-radius: 4px;
    font-family: Arial, Tahoma;
    cursor: pointer;
    transition: 0.2s;
    z-index: 99;
    text-align: center;
    border-radius: 50%;
    width: 50px;
    height: 50px;
}
.up:hover {
    background: #FABB25;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.upE {
    position: fixed;
    bottom: 3%;
    right: -50px;
    background-color: #FABB25;
    color: white;
    font-weight: bold;
    font-size: 25px;
    padding: 5px;
    border-radius: 4px;
    font-family: Arial, Tahoma;
    cursor: pointer;
    transition: 0.2s;
    width: 35px;
    z-index: 99;
    text-align: center;
}

.upE.show {
    left: 10px;
}

.upE:hover {
    background: #05305b;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}