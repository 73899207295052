.test::after {
        content: '';
    display: block;
    border-right: 1px solid;
    height: 50px;
    width: 24px;
    border-color: #d8d8d8;
    position: absolute;
    right: -16%;
    top: -5px;
}
.test::before {    
    content: '';
    display: block;
    border-right: 1px solid;
    height: 50px;
    width: 24px;
    border-color: #d8d8d8;
    position: absolute;
    left: -35%;
    top: -5px;
}