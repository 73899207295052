input {
    background-color: #FFF !important;
    width: 100%;
    height: 50px;
    border: none !important;
    outline: none !important;
    padding: 20px;
}
textarea,
textarea:focus,
textarea:focus-visible,
textarea:active {
border: none !important;
outline: none !important;
}
.css-11nnf0g-MuiFormControl-root-MuiTextField-root {
    background-color: #FFF !important;
}

.react-datepicker-wrapper {
    width: 100% !important;
}