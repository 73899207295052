.parent {
      width: 190px;
    height: 125px;
  background: #f2f2f2;
  border-radius: 8px;
  border: 1px solid #cce1e5;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  margin-bottom: 32px;
}

.parent img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
