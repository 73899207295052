/* .drw {
    height: 60vh !important;
} */
/* .css-1160xiw-MuiPaper-root-MuiDrawer-paper,
.css-1ab2xsx {
    color: #fff !important ;
    background-color: #000000e0 !important ;
    height: 70% !important ;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    
} */
.parentNav {
    padding: 10px;
    transition: all 0.7s ease-in-out;
}
.parentNav .cov {
    background-color: #F7F7F7;
    
}
.parentNav:hover {
    background-color: #FFF;
}

.parentNav p {
    color: #FFF;
}

.parentNav:hover p {
    color: #FABB25;
}