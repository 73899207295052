* {
  font-family: "Cairo", sans-serif;
}
body {
  margin: 0;
  font-family: "Cairo";
}
.gradientFull {
  background: #000000;
  height: calc(100vh - 80px);
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.myList li {
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 500px;
}
@media only screen and (max-width: 767px) {
  .myList li {
    width: 100%;
  }
}
.myList li:hover {
  background-color: #fafafa;
  color: #fabb25;
  padding-right: 20px;
  transition: all 0.7s ease-in-out;
  font-weight: 600;
}
.myList {
  list-style: none;
  padding: 0;
}
.link {
  color: #fff;
}
.link:hover,
.link:active,
.link:focus-visible,
.link:focus {
  color: #fabb25;
}
