
.line::after {
    content: '';
    height: 1px;
    width: 150px;
    color: #000;
    display: block;
    border: 1.5px solid #000;
    position: absolute;
    top:-30px;
    right: 0%;
    transform: skewY(-7deg);
    margin: 15px;
}
@media only screen and (max-width: 767px) {
    .line::after {
        content: '';
        height: 1px;
        width: 125px;
        color: #000;
        display: block;
        border: 0.5px solid #000;
        position: absolute;
        top:-5px;
        right: 5%;
        transform: skewY(-7deg);
        margin: 15px;
    }
}


.line::before {
    content: '';
    height: 1px;
    width: 150px;
    color: #000;
    display: block;
    border: 1.5px solid #000;
    position: absolute;
    bottom: -20px;
    left: 0%;
    transform: skewY(-7deg);
    margin: 15px;

}
@media only screen and (max-width: 767px) {
    .line::before {
    content: '';
    height: 1px;
    width: 125px;
    color: #000;
    display: block;
    border: 0.5px solid #000;
    position: absolute;
    bottom: -5px;
    left: 5%;
    transform: skewY(-7deg);
    margin: 15px;
}
}