.gradient{ 
background: #000000;
height: 85vh;
display: flex;
align-items: flex-end;
position: relative;
 overflow: hidden;
}

@media only screen and (max-width: 767px) {
    .gradient{
    height: 28rem;
    padding: 20px;
    }
}
.test1, 
.test2,
.test1En,
.test2En {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    z-index: 1;
}
.test1 {
    background-image: url('./../../assets/hero/Path 566.svg');
    right: -43%;
}
.test2 {
    background-image: url('./../../assets/hero/Path 567.svg');
        right: -45%;
}
.test1En {
    background-image: url('./../../assets/hero/Path 566.svg');
        left: -50%;
    top: -20%;
}
.test2En {
    background-image: url('./../../assets/hero/Path 567.svg');
    left: -42%;
}
.particle-canvas {
    z-index: 0; /* Ensure particles are behind other content */
}