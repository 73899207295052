
.lineP::before {
content: '';
height: 1px;
width: 125px;
color: #000;
display: block;
border: 0.5px solid #000;
position: absolute;
top:100%;
right: -5%;
transform: skewY(-7deg);
margin: 15px;
}
@media only screen and (max-width: 767px) {
    .lineP::before {
    content: '';
    height: 1px;
    width: 125px;
    color: #000;
    display: block;
    border: 0.5px solid #000;
    position: absolute;
    top:100%;
    right: -10%;
    transform: skewY(-7deg);
    margin: 15px;
}
}