.number {
     position: absolute;
    top: -15%;
    right: 5%;
    background: #dcdcdc;
    padding: 8px;
    border-radius: 50%;
width: 35px;
height: 35px;
text-align: center;
margin: auto;
line-height: 100%;
}
.numberLeft {
         position: absolute;
    top: -15%;
    left: 5%;
    background: #dcdcdc;
    padding: 8px;
    border-radius: 50%;
width: 35px;
height: 35px;
text-align: center;
margin: auto;
line-height: 100%;
}

@media only screen and (max-width: 767px) {
.numberLeft {
         position: absolute;
    top: -15%;
    right: 5%;
    background: #dcdcdc;
    padding: 8px;
    border-radius: 50%;
width: 35px;
height: 35px;
text-align: center;
margin: auto;
line-height: 100%;
}
}