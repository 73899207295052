.bgPackages {
    background-image: url('./../../../assets/StartNow/bgPack.png');
    height: 63rem;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center;
    padding: 30px;
    margin-top: 100px;
    margin-bottom: 100px;
    position: relative;
}