.hero {
    background-image: url('./../../assets/front-view-male-office-worker-sitting-his-working-place-talking.png');
    height: 60vh;
    padding: 10px 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    display: flex;
    justify-content: flex-end;
}

.text {
    position: absolute;
    top: 15%;
    right: 10%;
}